<template>
    <div class="@relative" :class="{addErrorBorder}">
        <input
            v-validity="message"
            type="text"
            required
            class="hiddenRequiredInput"
            tabindex="-1"
            onfocus="this.blur()"
        >
    </div>
</template>

<script>
    import {validity} from "@songfinch/shared/mixins/validity_directives";

    export default {
        name: "RequiredFormHelper",
        directives: {validity},
        props: {
            addErrorBorder: Boolean,
            message: {type: String, default: "Please fill out this field."},
        }
    };
</script>
