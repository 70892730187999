<template>
    <div class="splideSlider">
        <Splide
            ref="splide"
            :options="computedOptions"
            :class="sliderClass"
            v-bind="$attrs"
            :extensions="extensions"
            v-on="events"
        >
            <slot/>
        </Splide>
        <div v-if="arrows && showArrows">
            <div class="splide-prev" :style="arrowPrevStyle" @click.stop="prevSlide">
                <img :src="arrowIcon" alt="arrow prev">
            </div>
            <div class="splide-next" :style="arrowNextStyle" @click.stop="nextSlide">
                <img :src="arrowIcon" alt="arrow next">
            </div>
        </div>
    </div>
</template>

<script>
    import arrowIconCircle from "./carousel_arrow.png";
    import arrowIconNoCircle from "./carousel_arrow_no_circle.png";
    import {debounce} from "lodash-es";
    /*Be careful with type: 'loop' it's create a clones*/
    /* don't use rewind with perMove!!!! */
    export default {
        name: "Slider",
        props: {
            arrows: {
                type: Boolean,
                default: true
            },
            options: {
                type: Object,
                default: () => ({
                    drag: true
                })
            },
            events: {
                type: Object,
                default: () => ({})
            },
            sliderClass: {
                type: String,
                default: ""
            },
            arrowPrevStyle: {
                type: [Object, String],
                default: null
            },
            arrowNextStyle: {
                type: [Object, String],
                default: null
            },
            arrowCircled: {
                type: Boolean,
                default: false
            },
            customArrow: {
                type: [String, Object],
                default: null
            },
            extensions: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                showArrows: true,
                debounceFunc: null,
                defaultOptions: {arrows: true, rewind: true},
                arrowIconCircle,
                arrowIconNoCircle
            };
        },
        computed: {
            arrowIcon() {
                if (this.customArrow) return this.customArrow;
                return this.arrowCircled ? this.arrowIconCircle : this.arrowIconNoCircle;
            },
            computedOptions() {
                return {...this.defaultOptions, ...this.options};
            }
        },
        mounted() {
            if ( !this.$refs.splide ) return;
            this.checkArrows();
            this.$refs.splide.splide.cloneDestroy = this.$refs.splide.splide.destroy;
            this.$refs.splide.splide.destroy = () => undefined;
            this.debounceFunc = debounce(this.checkArrows, 500);
            window.addEventListener("resize", this.debounceFunc);
            window.dispatchEvent(new Event("resize"));
        },
        beforeUnmount() {
            const splide = this.$refs.splide.splide;
            setTimeout(() => splide.cloneDestroy, 1000);
            window.removeEventListener("resize", this.debounceFunc);
        },
        methods: {
            checkArrows() {
                this.showArrows = !!this.$refs.splide?.root.querySelector(".splide__slide:not(.is-visible)");
            },
            nextSlide() {
                this.$refs.splide.go( ">" );
            },
            prevSlide() {
                this.$refs.splide.go( "<" );
            },
            goto(param) {
                this.$refs.splide.go(param);
            }
        },
    };
</script>

<style scoped>
    .splideSlider {
        position: relative;
        //overflow: hidden;
        margin-bottom: 54px;

        :deep(.splide__arrow) {
            display: none !important;
        }

        .splide-prev, .splide-next {
            margin-top: 0;
            transform: translateY(-50%);
            position: absolute;
            top: 50%;
            width: 58px;
            cursor: pointer;
            transition: all 0.3s;
            display: none;

            @screen desktop {
                display: block;
            }

            &:before, &:after {
                display: none !important;
            }

            img {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                max-width: 100%;
            }

            &.splide-next {
                right: auto;
                left: 100%;

                img {
                    transform: translateY(-50%) rotate(180deg);
                }
            }

            &.splide-prev {
                left: auto;
                right: 100%;
            }

            &[disabled] {
                display: none;
            }
        }


        :deep(.splide__pagination) {
            position: absolute;
            bottom: -50px;

            li {
                button{
                    background: var(--sfc-dark-beige);
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    transform: none;
                    opacity: 1;
                    position: relative;
                    margin: 0 5px;
                    padding: 0;
                    cursor: pointer;

                    &.is-active {
                        background: var(--sfc-black);
                    }
                }

            }
        }
    }
</style>
